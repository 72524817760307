<template>
  <div class="formPart">
    <el-form size="mini" :model="query" label-width="150" inline>
      <template v-for="({ label, placeholder, model, type, dicData }, index) in orderFormData">
        <el-form-item :key="index" v-if="type === 'select'" :label="label">
          <el-select v-model="query[model]" :placeholder="placeholder" clearable @change="toQuery">
            <el-option v-for="item in dicData || dic[model]" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :key="index" v-else-if="type == 'daterange'" :label="label">
          <SaleDateTime :model="query" :timeProp="model" @change="toQuery" />
        </el-form-item>
        <el-form-item :key="index" v-else-if="label == 'button'">
          <el-button class="el-icon-search" type="primary" style="margin-left: -40px" @click="toQuery"></el-button>
        </el-form-item>
        <el-form-item :key="index" v-else :label="label" clearable>
          <el-input
            :placeholder="placeholder"
            clearable
            v-model="query[model]"
            @keyup.enter.native="toQuery"
            @clear="toQuery"
          ></el-input>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import selectInput from '@/views/components/selectInput'
import SaleDateTime from '@/components/saleDateTime'
import { commonEheaderMixin } from '@/mixins'
import { WAYBILL_CODE_DIC } from '@/utils/constant/fbaBusinessConst'
import { mapGetters } from 'vuex'

let orderFormData = [
  { label: '订单号', placeholder: '请输入订单号', model: 'orderCode' },
  { label: '分销商', placeholder: '请输入分销商', model: 'mainUserName' },
  {
    label: '物流方式',
    model: 'expressShippingMethodId',
    type: 'select',
    placeholder: '请选择'
  },
  { label: '时间', model: 'importTime', type: 'daterange' },
  {
    label: '是否有单号',
    model: 'expressWaybillIsCreated',
    type: 'select',
    dicData: WAYBILL_CODE_DIC,
    placeholder: '请选择'
  },
  { label: 'button' }
]
export default {
  mixins: [commonEheaderMixin],
  components: {
    selectInput,
    SaleDateTime
  },
  props: {
    query: {
      type: Object,
      required: true
    },
    orderStatus: {
      type: String
    },
    inDispatch: {
      type: Boolean,
      default: true
    },
    postData: {
      type: Object
    }
  },
  data() {
    return {
      formField: [{ prop: 'importTime' }]
    }
  },
  computed: {
    ...mapGetters(['expressShippingMethodList']),
    orderFormData({ orderStatus }) {
      if (orderStatus !== '') {
        return orderFormData.filter((item) => item.model !== 'orderStatus')
      }
      return orderFormData
    },
    dic() {
      return {
        expressShippingMethodId: this.expressShippingMethodList
      }
    }
  },
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.formPart {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 20px 5px;
  .el-form-item {
    margin-right: 50px;
  }
}
</style>
