<template>
  <div class="app-container flex-one-page">
    <div class="wrapper">
      <div v-scrollTop>
        <orderState
          ref="orderState"
          :query="query"
          :sup_this="sup_this"
          @updateCheck="updateCheck"
          @takeStatus="takeStatus"
        ></orderState>
        <formPart
          v-if="permission.search"
          ref="form"
          :query="query"
          :orderStatus="orderStatus"
          :sup_this="sup_this"
          :postData="postData"
          :inDispatch="false"
        ></formPart>
        <tablePart
          ref="table"
          class="flex-one"
          :sup_this="sup_this"
          :tableLoading="tableLoading"
          :orderListData="data"
          :orderStatus="orderStatus"
          :permission="permission"
          :isWaybillCreate="true"
          @takeFactory="takeFactory"
        ></tablePart>
      </div>
      <div class="hidden_block"></div>
      <div class="pagination_wrapper">
        <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
      </div>
    </div>
  </div>
</template>

<script>
import { initDataMixin } from '@/mixins'
import orderState from './module/orderListModule/orderState'
import formPart from './module/orderListModule/formPart'
import tablePart from './module/orderListModule/tablePart'
import { getArrFromStr } from '@/utils'

export default {
  name: 'orderOrderList',
  mixins: [initDataMixin],
  components: {
    orderState,
    formPart,
    tablePart
  },
  data() {
    return {
      sup_this: this,
      url: '/externaladmin/orderService/order/orderFbaList',
      method: 'post',
      initBool: false,
      mergeData: {
        orderItems: [
          {
            asc: false,
            column: 'order_time'
          },
          {
            asc: false,
            column: 'id'
          }
        ],
        isFormatChildName: 1,
        isReturnRelatedData: 1,
        isReturnWaybillChangeRecord: 1
      },
      orderStatus: '0',
      factoryData: [],
      permission: {},
      postData: {}
    }
  },
  computed: {
    handleSearchFormProps() {
      return {
        orderCodeList: (val) => getArrFromStr(val),
        expressWaybillCodeList: (val) => getArrFromStr(val)
      }
    }
  },

  mounted() {
    this.permission = this.$refs.orderState.childPermission || {}
    if (!this.permission) {
      this.$message.error('页面无权限！')
    }
  },
  methods: {
    beforeInitData() {
      this.postData = this.params
    },
    initCallBack(res) {
      // console.log('ressssssss', res)
      let tempArr = []
      this.data.forEach((item) => {
        tempArr.push({
          isHeader: true,
          orderCode: item.orderCode,
          userName: item.userName,
          orderInfo: item
        })
        tempArr.push(item)
      })
      this.data = tempArr
      console.log(this.data)
    },
    takeStatus(data) {
      this.orderStatus = data
    },
    takeFactory(data) {
      this.factoryData = data
      console.log(this.factoryData)
    },
    updateCheck(newCheck) {
      this.permission = newCheck
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  overflow: hidden;
  padding: 20px 0 95px 15px;
  position: relative;
  .wrapper {
    height: 100%;
    overflow: auto;
    border-bottom: 1px solid #ebeef5;
    padding-right: 15px;
  }
  .pagination_wrapper {
    position: fixed;
    bottom: 32px;
    right: 45px;
    // background-color: rgb(25, 224, 158);
    z-index: 10;
  }
  .hidden_block {
    position: absolute;
    bottom: 95px;
    right: 0;
    width: 23px;
    height: 1px;
    background-color: #fff;
  }
  ::v-deep {
    .el-table {
      overflow: hidden;
    }
    .list-state-group .el-radio-button__inner {
      width: 135px;
    }
  }
}
</style>
