<template>
  <el-input
    v-model="text"
    size="small"
    class="input-with-select"
    clearable
    :placeholder="inputPlaceholder[curType]"
    @input="oninput"
    @keyup.enter.native="onchange"
    @clear="onchange"
  >
    <avue-crud-select slot="prepend" v-model="curType" :dic="dicData" :clearable="false"></avue-crud-select>
  </el-input>
</template>

<script>
export default {
  props: {
    dicData: Array,
    form: {
      type: Object,
      default: () => ({})
    },
    // 改变方式清空
    clear: Boolean
  },
  data() {
    return {
      curType: '',
      text: ''
    }
  },
  computed: {
    inputPlaceholder({ dicData }) {
      let tempObj = {}
      dicData.forEach((item) => {
        tempObj[item.value] = item.placeholder || `输入${item.label}`
      })
      return tempObj
    },
    ignoreKeysObj({ dicData }) {
      return dicData.reduce((prev, next) => {
        prev[next.value] = undefined
        return prev
      }, {})
    }
  },
  watch: {
    form: {
      handler(n) {
        this.text = n[this.curType] || ''
      },
      immediate: true
    },
    curType() {
      let val = this.text
      if (this.clear) {
        this.text = ''
        this.changeForm('')
        this.$emit('input', '')
        this.$emit('change', '')
        return
      }
      if (val) {
        this.changeForm(val)
        this.$emit('input', val)
        this.$emit('change', val)
      }
    },
    dicData: {
      handler(n) {
        this.curType = n[0].value
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    oninput() {
      let val = this.text
      this.changeForm(val)
      this.$emit('input', val)
    },
    onchange() {
      let val = this.text
      this.changeForm(val)
      this.$emit('change', val)
    },
    changeForm(val = this.text) {
      Object.assign(this.form, this.ignoreKeysObj, {
        [this.curType]: val
      })
    }
  }
}
</script>

<style lang="scss">
.input-with-select {
  .el-select {
    width: 115px;
  }
}
</style>
